import styled from "styled-components";
import * as Colors from "@constants/colors";

export const StyledTextarea = styled.textarea`
  border: none;
  outline: none;
  border-bottom: 1px solid ${({ borderColor }) => borderColor};
  background: transparent;
  color: ${({ color }) => color};
  font-family: arial;
  font-size: 24px;
  line-height: 26px;
  width: 100%;
  padding-bottom: 12px;
  resize: none;

  :focus {
    border-bottom-color: ${Colors.beachTeal};
  }
`;
