import styled from "styled-components";
import * as Colors from "@constants/colors";
import * as Fonts from "@constants/fonts";

export const FileInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 134px;
  background-color: ${Colors.goldenHourLighter};
  border-radius: 4px;
  position: relative;
`;

export const HiddenInput = styled.input`
  width: 77px;
  height: 38px;
  opacity: 0;
  position: absolute;
`;

export const StyledLabel = styled.label`
  width: 77px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.charcoal};
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  line-height: 22px;
  font-family: ${Fonts.ProximaNovaFont};
  font-weight: bold;
`;

export const FileName = styled.p`
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 22px;
  font-family: ${Fonts.ProximaNovaFont};
  font-weight: bold;
  color: ${Colors.black};
`;
