import React, { useState } from "react";
import * as Colors from "@constants/colors";
import { Wrapper } from "@components/shared.styled";
import TextInput from "@components/text-input";
import Textarea from "../../components/textarea";
import FileInput from "../../components/file-input";
import {
  StyledForm,
  FormContainer,
} from "../get-in-touch-form/get-in-touch-form.styled";
import { StyledFormGroup, FormRow, ShortButton } from "./position-form.styled";
import {
  validEmailFormat,
  validPhoneNumberFormat,
  validNameFormat,
} from "@constants/validation";

const linkedInLabel = (
  <>
    LinkedIn Profile <span>Optional</span>
  </>
);

const locationLabel = (
  <>
    Location (City) <span>Optional</span>
  </>
);

const coverLetterLabel = (
  <>
    Cover Letter <span>Optional</span>
  </>
);

const personalDataFields = [
  {
    type: "text",
    label: "Last name",
    name: "lastName",
    id: "last-name",
    placeholder: "Your last name",
    isValidated: true,
  },
  {
    type: "email",
    label: "Email",
    name: "email",
    id: "email",
    placeholder: "Your email address",
    isValidated: true,
  },
  {
    type: "tel",
    label: "Phone No.",
    name: "phoneNumber",
    id: "phone-number",
    placeholder: "Your phone number",
    isValidated: true,
  },
  {
    type: "text",
    label: linkedInLabel,
    name: "linkedInProfile",
    id: "linkedin-profile",
    placeholder: "Your LinkedIn profile URL",
    isValidated: false,
  },
  {
    type: "text",
    label: locationLabel,
    name: "location",
    id: "location",
    placeholder: "Your location",
    isValidated: false,
  },
];

const PositionForm = ({ title }) => {
  const [errors, setErrors] = useState({});

  const getFieldError = (value, format) => {
    if (!value) return "Field is required";
    return !format || format.test(value) ? null : "Invalid format";
  };

  const handleSubmit = e => {
    const errors = {
      firstName: getFieldError(
        e.target.elements.firstName.value,
        validNameFormat
      ),
      lastName: getFieldError(
        e.target.elements.lastName.value,
        validNameFormat
      ),
      email: getFieldError(e.target.elements.email.value, validEmailFormat),
      phoneNumber: getFieldError(
        e.target.elements.phoneNumber.value,
        validPhoneNumberFormat
      ),
    };

    if (Object.values(errors).some(error => error)) {
      setErrors(errors);
      e.preventDefault();
    }
  };

  return (
    <FormContainer backgroundColor={Colors.white}>
      <Wrapper>
        <StyledForm
          name="jobSubmission"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          action="/we-will-be-in-touch/"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <input type="hidden" name="form-name" value="jobSubmission" />
          <p hidden>
            <label>
              Don’t fill this out: <input name="bot-field" />
            </label>
          </p>
          <input
            type="hidden"
            name="positionTitle"
            placeholder="Position title"
            value={title}
          />
          <StyledFormGroup>
            <TextInput
              type="text"
              label="First name"
              name="firstName"
              id="first-name"
              placeholder="Your first name"
              color={Colors.charcoal}
              borderColor={Colors.black}
              error={errors["firstName"]}
            />
          </StyledFormGroup>
          {personalDataFields.map(
            ({ type, label, name, id, placeholder, isValidated }) => (
              <StyledFormGroup key={id}>
                <TextInput
                  type={type}
                  label={label}
                  name={name}
                  id={id}
                  placeholder={placeholder}
                  color={Colors.charcoal}
                  borderColor={Colors.black}
                  error={isValidated && errors[name]}
                />
              </StyledFormGroup>
            )
          )}
          <FormRow>
            <Textarea
              label={coverLetterLabel}
              name="coverLetter"
              id="coverLetter"
              rows={5}
              color={Colors.charcoal}
              borderColor={Colors.black}
            />
          </FormRow>
          <FormRow>
            <FileInput
              label="Resume"
              name="file"
              id="file"
              type="file"
              color={Colors.charcoal}
              borderColor={Colors.black}
              error={errors.file}
            />
          </FormRow>
          <ShortButton type="submit" text="Apply Now" />
        </StyledForm>
      </Wrapper>
    </FormContainer>
  );
};

export default PositionForm;
