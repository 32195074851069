import styled from "styled-components";
import * as Colors from "@constants/colors";
import * as Fonts from "@constants/fonts";
import { Screens } from "@constants/screens";
import { Link } from "gatsby";

const Container = styled.section`
  background: ${Colors.white};
  padding-top: 112px;
  @media (max-width: ${Screens.lg}) {
    padding-top: 50px;
  }
`;

const Content = styled.div`
  max-width: 643px;
  margin: 0 auto;
`;

const ReturnLink = styled(Link)`
  color: ${Colors.primary};
  font-family: ${Fonts.ProximaNovaFont};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  span {
    text-decoration: underline;
  }
`;

const CategoryContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  @media (max-width: ${Screens.lg}) {
    justify-content: center;
  }
`;

const Category = styled.p`
  display: inline-block;
  font-family: ${Fonts.ProximaNovaFont};
  padding: 6px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.charcoal};
  background-color: ${Colors.light};
  border-radius: 16px;
  margin: 48px 0 32px 0;
`;

const Title = styled.h2`
  font-family: ${Fonts.ClearfaceFont};
  font-size: 48px;
  font-weight: bold;
  margin: 0 0 16px;
  @media (max-width: ${Screens.lg}) {
    text-align: center;
    font-size: 28px;
  }
`;

const Department = styled.h4`
  font-family: ${Fonts.ClearfaceFont};
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  margin: 0 0 64px;
  @media (max-width: ${Screens.lg}) {
    text-align: center;
    margin: 0 0 40px;
  }
`;

const Description = styled.div`
  line-height: 24px;
  p {
    font-family: ${Fonts.ProximaNovaFont};
    font-size: 16px;
    margin: 0 0 26px;
    strong {
      font-style: italic;
      font-weight: 600;
    }
  }
  h3 {
    font-family: ${Fonts.ProximaNovaFont};
    font-size: 20px;
    margin: 32px 0 26px;
  }
  ul {
    margin-bottom: 26px;
  }
  li {
    margin-bottom: 26px;
  }
  a {
    color: ${Colors.primary};
  }
`;

export {
  Container,
  Content,
  ReturnLink,
  CategoryContainer,
  Category,
  Title,
  Department,
  Description,
};
