import styled from "styled-components";
import { Screens } from "@constants/screens";
import {
  FormGroup,
  StyledButton,
} from "../get-in-touch-form/get-in-touch-form.styled";

export const StyledFormGroup = styled(FormGroup)`
  height: unset;
  margin: 0 0 48px;
  @media (max-width: ${Screens.lg}) {
    margin: 0 auto 32px;
  }
`;

export const FormRow = styled.div`
  width: 100%;
  margin: 0 0 80px;

  @media (max-width: ${Screens.lg}) {
    max-width: 415px;
    margin: 0 auto 60px;
  }
`;

export const ShortButton = styled(StyledButton)`
  max-width: 202px;
  margin: 0 auto;
`;
