import React from "react";
import { graphql } from "gatsby";
import { Wrapper } from "@components/shared.styled";
import Layout from "@components/layout";
import Form from "@modules/position-form";
import convertMarkdownToHtml from "@helpers/convert-markdown-to-html";
import {
  Container,
  Content,
  ReturnLink,
  CategoryContainer,
  Category,
  Title,
  Department,
  Description,
} from "./position.styled";

const Position = ({ data }) => {
  const { title, department, location, description } =
    data.position.nodes[0].frontmatter;

  return (
    <Layout>
      <Container>
        <Wrapper>
          <Content>
            <ReturnLink to="/careers#openings">
              ← <span>Return to Listings</span>
            </ReturnLink>
            <CategoryContainer>
              <Category>{department}</Category>
            </CategoryContainer>
            <Title>{title}</Title>
            <Department>{location}</Department>
            <Description
              dangerouslySetInnerHTML={{
                __html: convertMarkdownToHtml(description),
              }}
            />
            <Form title={title} />
          </Content>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    position: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/positions/" }
        frontmatter: { slug: { eq: $slug } }
      }
    ) {
      nodes {
        frontmatter {
          slug
          title
          department
          location
          description
        }
      }
    }
  }
`;

export default Position;
