import React, { useState, useRef } from "react";
import * as Colors from "@constants/colors";
import { ErrorMessage, Label } from "../text-input/text-input.styled";
import {
  FileInputContainer,
  HiddenInput,
  StyledLabel,
  FileName,
} from "./file-input.styled";

const FileInput = ({
  color = Colors.white,
  name,
  id,
  label,
  type = "file",
  required,
  error,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInput = useRef();

  const changeHandler = event => {
    if (event.target.files.length) {
      setSelectedFile(event.target.files[0]);
    }
  };
  const clickHandler = () => {
    fileInput.current.value = null;
    setSelectedFile(null);
  };

  return (
    <>
      <Label color={color}>{label}</Label>
      <FileInputContainer>
        {selectedFile && <FileName>{selectedFile.name}</FileName>}
        <HiddenInput
          name={name}
          id={id}
          type={type}
          required={required}
          onChange={changeHandler}
          ref={fileInput}
        />
        {selectedFile ? (
          <StyledLabel as="div" onClick={clickHandler}>
            Reset
          </StyledLabel>
        ) : (
          <StyledLabel htmlFor={id}>Upload</StyledLabel>
        )}
      </FileInputContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

export default FileInput;
