import React from "react";
import { StyledTextarea } from "./text-input.styled";
import * as Colors from "@constants/colors";
import { Label } from "../text-input/text-input.styled";

const Textarea = ({
  borderColor = Colors.white,
  color = Colors.white,
  id,
  label,
  name,
  placeholder = "",
  placeholderColor = Colors.gray,
  rows = 5,
  required = false,
}) => (
  <>
    <Label htmlFor={id} color={color}>
      {label}
    </Label>
    <StyledTextarea
      borderColor={borderColor}
      color={color}
      id={id}
      name={name}
      placeholder={placeholder}
      placeholderColor={placeholderColor}
      required={required}
      rows={rows}
    />
  </>
);

export default Textarea;
